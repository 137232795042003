import { useEffect, useState } from "react";
import Swal from "sweetalert2";

import Title from "../components/Title";

import data from "../data/gallery.json";
import Starts from "../components/Until";

const PARTNERS = {
	"livetv.png": 100,
	"mnb.jpg": 100,
	"youtube_live.png": 100,
	"fb_live.jpg": 100,
};

const lives = [
	{
		label: "DAY 1 LIVE VIDEOS",
		videos: [
			{
				frame: (
					<iframe
						width="100%"
						height="315"
						src="https://www.youtube.com/embed/rBKWqp1bL6c?si=ExJkJYYxFSihwbE1"
						title="YouTube video player"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowfullscreen
					></iframe>
				),
				name: "Competiton Round One",
			},
			{
				frame: (
					<iframe
						width="100%"
						height="315"
						src="https://www.youtube.com/embed/9K4euSzujq0?si=SPo-XQV5SFzDpZNR"
						title="YouTube video player"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowfullscreen
					></iframe>
				),
				name: "Weight Leg 1",
			},
		],
	},
	{
		label: "DAY 2 LIVE VIDEOS",
		videos: [
			{
				frame: (
					<iframe
						width="100%"
						height="315"
						src="https://www.youtube.com/embed/joRDhK7Jfl0?si=-8OFdfQ4Re5iw04L"
						title="YouTube video player"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowfullscreen
					></iframe>
				),
				name: "Competiton Round Two",
			},
			{
				frame: (
					<iframe
						width="100%"
						height="315"
						src="https://www.youtube.com/embed/OzUEEKVAa3A?si=XD_LHVO32TFLBaLn"
						title="YouTube video player"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowfullscreen
					></iframe>
				),
				name: "Final Weight",
			},
			{
				frame: (
					<iframe
						width="100%"
						height="315"
						src="https://www.youtube.com/embed/PRT12ujWtsE?si=MIcJq5_JreXw6xBY"
						title="YouTube video player"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowfullscreen
					></iframe>
				),
				name: "Ending Ceremony",
			},
		],
	},
];

const Media = () => {
	const [gallery, setGallery] = useState([]);

	useEffect(() => {
		const tmp = [];
		for (let g of data) {
			let chunks = [];
			const chunkSize = parseInt(g.images.length / 4);

			for (let i = 0; i < g.images.length; i += chunkSize) {
				chunks.push(g.images.slice(i, i + chunkSize));
			}

			tmp.push({ name: g.name, chunks });
		}

		setGallery(tmp);
	}, []);

	const openModal = (image) => {
		return Swal.fire({
			imageUrl: image,
			imageAlt: image,
			heightAuto: true,
			width: 1000,
			showConfirmButton: false,
			showCloseButton: true,
			padding: 5,
		});
	};

	return (
		<>
			<Starts></Starts>
			<div className="bg-white p-10" style={{ minHeight: 500 }}>
				<div
					className="flex flex-wrap justify-center items-center gap-12 px-5 mx-auto my-12"
					// style={{ maxWidth: 800 }}
				>
					{Object.entries(PARTNERS).map(([key, height]) => (
						<img
							key={key}
							className="w-auto object-contain"
							style={{ height }}
							src={`img/partners/${key}`}
							alt={key}
						/>
					))}
				</div>

				<div className="my-10 flex flex-col gap-10">
					{lives.map((live, index) => {
						return (
							<div key={index}>
								<Title justify="left">{live.label}</Title>
								<div className="flex flex-wrap justify-around gap-5">
									{live.videos.map(({ name, frame }) => (
										<div key={name} className="w-full md:w-1/2">
											<div className="uppercase">{name}</div>
											<div>{frame}</div>
										</div>
									))}
								</div>
							</div>
						);
					})}
				</div>
				<div className="relative z-10 text-sm mt-8">
					{gallery.map(({ name, chunks }) => (
						<div key={name} className="mt-8">
							<Title justify="left">{name}</Title>
							<div className="grid grid-cols-2 md:grid-cols-4 gap-5">
								{chunks.map((chunk, index) => (
									<div key={index} className="grid gap-5">
										{chunk.map((image) => {
											const src = `img/gallery/${image}`;

											return (
												<div
													key={image}
													className="cursor-pointer hover:opacity-60"
													onClick={() => {
														openModal(src, name);
													}}
												>
													<img
														className="h-auto max-w-full"
														src={src}
														alt={name}
													/>
												</div>
											);
										})}
									</div>
								))}
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default Media;
